.CompetenceRateBox {
    display: flex;
    justify-content: stretch;
    border-radius: 5px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, .2);
    border: solid 1px rgba(0, 0, 0, 0.1);
}

.CompetenceRateBox.small {
    border-radius: 0px;
    box-shadow: none;
    border: none;
}

.CompetenceRateBox .rateButton {
    flex-grow: 1;
    display: inline-block;
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
    /* Grün mit variabler Helligkeit */
    padding: 0px 5px;
}

.CompetenceRateBox select {
    margin-right: 10px;
    padding: 5px;

}

.CompetenceRateBox select,
.CompetenceRateBox option {
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
    /* Grün mit variabler Helligkeit */
}

.CompetenceRateBox select:focus {
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */

}

.CompetenceRateBox select {
    max-width: 150px;
}

/* 
@media (max-width: 700px) {
    .CompetenceRateBox .rateButton
    {
        display: none;
    }
        .CompetenceRateBox select
    {
        display: inherit;
    }
}

.CompetenceRateBox.small .rateButton{
    display: none;
}
.CompetenceRateBox.small select{
    display: inherit;
} */