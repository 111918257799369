.Button {
    display: flex;
    padding: 5px 10px;
    background-color: var(--var-primary-color);
    color: var(--var-on-primary-color);
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 #003;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s ease;
    transition: filter 0.3s ease;
    align-items: center;
    font-weight: 400;
    /* overflow: hidden; */
}

.Button .buttonText {
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: "Open Sans", "Helvetica", "Arial", sanf-serif;
}

.Button:hover,
.Button:focus {
    filter: brightness(80%);
}

.Button .icon {


    line-height: 10px;
    margin-right: 7px;
}

.Button.Button.verysmall .icon {
    margin-right: 0px;
}

.Button.small {
    padding: 0px 5px;
}

.Button.verysmall {
    padding: 2px 7px;
}

.Button.small .icon {
    vertical-align: -6px;
}

.Button.normal {
    background-color: white;
    color: black;
}

.Button.red {
    background-color: rgb(240, 62, 62);
    color: rgb(255, 255, 255);
}

.Button.green {
    background-color: rgb(14, 135, 20);
    color: rgb(255, 255, 255);
}

.Button.primary {
    background-color: var(--var-primary-color);
    color: var(--var-on-primary-color);
}

.Button.secondary {
    background-color: var(--var-secondary-color);
    color: var(--var-on-secondary-color);
}

.Button.disabled {
    pointer-events: none;
    background-color: rgba(124, 126, 128, 1);
}