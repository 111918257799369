.RateBar {
    display: flex;
    justify-content: stretch;
    border-radius: 5px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, .2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    cursor: pointer;
    user-select: none;
}

.RateBar.small {
    border-radius: 0px;
    box-shadow: none;
    border: none;
}

.RateBar .rateButton {
    flex-grow: 1;
    display: flex;
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
    /* Grün mit variabler Helligkeit */
    padding: 0px 5px;
    align-items: center;
    justify-content: center;
}

.RateBar select {
    margin-right: 10px;
    padding: 5px;

}

.RateBar select,
.RateBar option {
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
    /* Grün mit variabler Helligkeit */
}

.RateBar select:focus {
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */

}

.RateBar select {
    max-width: 150px;
}

/* 
@media (max-width: 700px) {
    .RateBar .rateButton
    {
        display: none;
    }
        .RateBar select
    {
        display: inherit;
    }
}

.RateBar.small .rateButton{
    display: none;
}
.RateBar.small select{
    display: inherit;
} */