.userProfile .infoBox {
    display: grid;
    grid-template-columns: minmax(auto, 140px) minmax(100px, auto) 28px;
    gap: 0px 5px;
}

.userProfile .label {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    font-weight: 600;
}

.userProfile .label.center {
    display: flex;
    align-items: center;
}

/* .userProfile .info {
    margin-bottom: 3px;
} */