.entitySearchBox .sugestions {
    position: relative;

    background-color: #fff;
    border-top: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;

    margin-top: -1px;
}

.entitySearchBox .sugestion {
    border-bottom: solid 1px black;
    padding: 3px;
    cursor: pointer
}

.entitySearchBox .sugestion:hover,
.entitySearchBox .sugestion:focus {
    background-color: #ccc;
}

.entitySearchBox {
    height: 22px;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.entitySearchBox input {

    flex-grow: 1;
    padding: 3px;
    padding-left: 7px;
}

.MuiAutocomplete-popper {
    z-index: 10000 !IMPORTANT;
}