.categorizeBox .catBtn {
    margin-top: 10px;
}

.dialogRightButtons {
    float: right;
    display: flex;
    gap: 20px;
}

.addBox {
    margin: 10px 0px;
}

.categorizeBox .categoriesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.categorizeBox .categoriesContainer .categorySkills {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.categoryTitle {
    display: flex;
    justify-content: space-between;
}

.nameBox {
    display: flex;
    align-items: center;
}

.categoryTitle .editBox {
    cursor: pointer;
    margin-left: 5px;
    height: 26px;

}

.categoryTitle .removeBox {
    cursor: pointer;
    height: 26px;

}

.categorizeBox .categoriesContainer .categorySkills>*>.BoxItem {
    margin: 0px;
}