.selectLevel {
    display: flex;
    align-items: center;
    gap: 3px;
}

.selectLevel .selectLevelIcons {
    cursor: pointer;
}

.addSkillButton.removeButton {
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;
}