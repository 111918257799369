.rolesInput {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    box-sizing: border-box;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    padding: 5px;
    border: solid 1px #efefef;
    border-radius: 5px;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
}

.rolesInput .roles {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px;
    padding: 0px 5px;
    width: 100%;
    justify-content: flex-start;
}

.rolesInput .role {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    border: solid 1px #efefef;
    padding: 6px 7px;
    border-radius: 10px;
    margin: 0px 0;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
    user-select: none;
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
    /* Grün mit variabler Helligkeit */
}

.rolesInput.small {
    box-shadow: none;
    padding: 2px;
    gap: 5px 5px;
    border: none;
    border-radius: 0px;
}

.rolesInput.small .role {
    font-size: 14px;
    padding: 2px 3px;
    box-shadow: none;

}

.rolesInput .newRoleRow {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    gap: 5px;
    align-items: center;


    border: solid 1px #efefef;
    padding: 6px 7px;
    border-radius: 10px;
    margin: 0px 0;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
    user-select: none;


    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
    /* Grün mit variabler Helligkeit */
}

.rolesInput .newRoleRow input {
    border-radius: 5px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, .2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.3);
}

/* .rolesInput .newRoleRow  > div{
    flex-grow: 1;
} */