.table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Leichter Schatten für Tiefe */
    margin: 0 auto;
    /* Zentriert die Tabelle */
}

.tableRow {
    background-color: #fff;
}

.tableRow:nth-child(odd) {
    background-color: #f9f9f9;
}

.tableRow:nth-child(even) {
    background-color: #e0e0e0;
}

.tableCell {
    padding: 12px 8px;
    /* Etwas mehr Polsterung für mehr Raum */
    text-align: left;
    /* Links ausgerichteter Text */
}

.tableCell:first-child {
    font-weight: bold;
    /* Fett für die Titelzelle */
}

.link {
    cursor: pointer;
    color: var(--var-secondary-color);
    text-decoration: none;
    transition: color 0.3s ease;
    /* Sanfter Übergang für Linkfarben */
}

.link:hover {
    text-decoration: underline;
    color: var(--var-primary-color);
    /* Ändert die Farbe beim Hover */
}