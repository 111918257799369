.CardBox {
    display: flex;
    flex-direction: column;

    /* padding: 6px 10px; */
    padding: 5px 20px 20px 20px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);

    border: solid 1px #d0d0d0;
    border-radius: 8px;
    font-size: 0.8rem;
    flex-grow: 1;
}

.boxHeader {
    display: flex;
    gap: 5px;
    font-size: 19px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    border-bottom: solid 1px #c1c1c1;
    margin-bottom: 10px;
    user-select: none;
}

.boxHeader .center {
    /* display: flex; */
    /* justify-content: center; */
    text-align: center;
    flex-grow: 1;
}

.boxHeader .center .title {
    word-break: break-word;
    font-weight: 400;
    line-height: 2;
    font-size: 1rem;
}