.iconContainer {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.button {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 5px;
    min-width: min(150px, 40%);
    border: 1px solid #ccc;
    transition:
        color 0.5s,
        background-color 0.5s,
        border-radius 1.5s;
}

.button:hover {
    color: var(--var-on-secondary-color);
    background-color: var(--var-secondary-color);
    border-radius: 10px;
}

.actionIcon {
    width: 24px;
    height: 24px;
    transition: filter 0.5s;
    /* background-color: #fff; */
    /* border-radius: 45%; */
    /* border: solid 1px #000; */
}

.button:hover .actionIcon,
.button.selected .actionIcon {
    filter: invert(100%);
}