.overlay {
    position: relative;
    /* position: absolute; */
    /* margin-left: 50px; */
    /* background-color: white; */
    /* border: solid 1px black; */
    /* border-radius: 10px; */
    /* padding: 10px; */
    /* min-width: 500px; */
    overflow: hidden;
    /* margin-top: -20px; */
}

.outerOverlay {
    width: 100%;
}

.overlay .nameOverlay {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
}

.overlay .error {
    padding: 10px;
}