.buttons {
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
    justify-content: stretch;
    justify-items: stretch;
    margin: 7px 0px;
}

.buttons * {
    flex-grow: 1;
}