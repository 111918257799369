.availabilityMonthDialog {
    padding: 10px 0px;
}

.availabilityMonthDialog span.MuiButtonBase-root.MuiRadio-root {
    padding: 3px 6px;
    margin-left: 3px;
}

.availabilityMonthDialog .numberBox {
    display: flex;
    width: 60px;
}

.availabilityMonthDialog .helpers {
    display: flex;
    gap: 20px
}

.activityTable {
    border-collapse: collapse;
    margin-top: 10px;
    margin-bottom: 15px;
}

.activityTable td,
.activityTable thead,
.activityTable .thead,
.activityTable tfoot,
.activityTable .tfoot {

    border: 1px solid #000;
    padding: 4px;
}

.activityTable thead,
.activityTable .thead {
    background-color: #ccc;
    padding: 8px;
    font-weight: 600;
}

.activityTable tr:nth-child(even) {
    background-color: #ddd;
}


.activityTable tbody {
    font-weight: 400;
}

.activityTable tfoot td,
.activityTable .tfoot td {
    padding: 0px;
    margin: 0px;
    background-color: #ccc;
    font-weight: 600;
}

.activityTable .sumBox {
    padding: 4px 4px !important;
}

.activityTable td.weekend {
    background-color: #ccc;
}

.activityTable .high {
    background-color: #a2181843;
    justify-content: stretch;
}

.activityTable .veryhigh {

    background-color: #a2181882;
    justify-content: stretch;
}

.activityTable .muchToHigh {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #d31313;
    color: #fff;
}

.activityTable .projectColumn {
    display: flex;
    flex-direction: column;
}

.activityTable .projectColumn .business {
    font-size: 11px;
}

.availabilityMonthDialog .arrowBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.availabilityMonthDialog .arrowBox .weekName {
    font-size: 18px;
}

.availabilityMonthDialog .warning {
    animation: flickerAnimation 1.5s infinite;
}

.availabilityMonthDialog .activityHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.availabilityMonthDialog .hideProjects {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.availabilityMonthDialog #addMonthButton {
    cursor: pointer;
}

.availabilityMonthDialog #addMonthButton:hover {
    background-color: var(--var-primary-color);
    color: var(--var-on-primary-color);
}

.availabilityMonthDialog #addMonthButton:hover>* {
    color: var(--var-on-primary-color);
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}