.EditOuList {
    padding: 10px;


}

.EditOuList .ouBox {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, .01);
    border: solid 1px black;
    border-radius: 5px;
    padding: 10px;
    gap: 15px;

}

.EditOuList .ouButtons {
    display: flex;
    gap: 5px;
    font-weight: 600;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.EditOuList .ouHead {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;

}

.EditOuList .leaderInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;

}

.EditOuList .ouTitle {
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 16px;
    font-weight: 600;
}

.EditOuList .editTitle .editTitleIcon {
    cursor: pointer;

}

.EditOuList .ouTitle .editTitleIcon {
    cursor: pointer;

}

.EditOuList .employees {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;

}

.EditOuList .employee {
    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(34, 255, 52, 0.05);

    flex-grow: 1;
    cursor: pointer;
}

.EditOuList .addEmployee {
    display: flex;
    align-items: center;
    padding: 2px 0;
    border: solid 1px black;
    border-radius: 5px;
    background-color: var(--var-primary-color);
    color: var(--var-on-primary-color);
    cursor: pointer;
}


.editUserDialog {
    font-size: 15px;
    font-weight: 400;
}

.editUserDialog .editUserRow {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    /* margin-top: 20px; */
}

.addUserDialog {
    font-size: 15px;
    font-weight: 400;
}

.addUserDialog .newUserRow {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 15px;
    flex-wrap: wrap;
}

.addUserDialog .newUserRowElement {

    float: left;
}

.addUserDialog .unsavedUsers {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;

    flex-wrap: wrap;
}

.addUserDialog .unsavedUser {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-wrap: wrap;

    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(34, 255, 52, 0.05);
    flex-grow: 1;
}

.addUserDialog .unsavedUser .headLine {
    display: flex;
    flex-grow: 1;

}

.addUserDialog .unsavedUser .titleNewEmployee {
    flex-grow: 1;
}

.addUserDialog .unsavedUser .removeUser {
    user-select: none;
    cursor: pointer;


}

.addUserDialog .employeeName {
    font-size: 16px;
    font-weight: 600;
}


.addOUDialog {
    font-size: 15px;
    font-weight: 400;
}

.addOUDialog .newOURow {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    /* margin-top: 20px; */


}

.addOUDialog .unsavedOUs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;

    flex-wrap: wrap;
}

.addOUDialog .unsavedOU {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-wrap: wrap;

    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(34, 255, 52, 0.05);
    flex-grow: 1;
}

.addOUDialog .unsavedOU .headLine {
    display: flex;
    flex-grow: 1;

}

.addOUDialog .unsavedOU .titleNewOU {
    flex-grow: 1;
}

.addOUDialog .unsavedOU .removeOU {
    user-select: none;
    cursor: pointer;


}

.addOUDialog .ouName {
    font-size: 16px;
    font-weight: 600;
}