.addRoleButton.removeButton {
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;
    overflow: hidden;
    width: 20px;
    transition: width 0.5s ease 0.75s;
}

/* VerySmall */

.addRoleButton.removeButton.verysmall {
    width: 20px;
}

.addRoleButton.removeButton.verysmall:hover {
    /* width: 43px !important; */
}

/* Small */
.addRoleButton.removeButton.small {
    width: 34px;
}

.addRoleButton.removeButton.small:hover {
    /* width: 71px; */
}