.wrapper {
  display: flex;
  /* flex-direction: row; */
  /* max-width: 1536px; */
  width: 100%;
  max-width: var(--var-wrapper-width);
  margin-left: auto;
  margin-right: auto;

  /* gap: 10px; */
}

.outerWrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  /* max-height: calc(100vh - var(--var-header-height)); */
  background-color: var(--var-background-color);
}

.navIsClosed {
  --nav-size: 0px;
}

.navIsOpen {
  --nav-size: 160px;
}


/* .wrapper.fixed>.navIsOpen {
  --nav-size: 0px;
} */

/* .footer {
  display: flex;
  gap: 30px;
  font-size: 15px;
  width: 100%;
  justify-content: center;
  height: var(--var-footer-height);
  align-items: center;
  color: var(--var-on-secondary-color);
  background-color: var(--var-secondary-color);
} */

.footer .imprint,
.footer .contact,
.footer .copyRight,
.footer .version {
  color: var(--var-on-secondary-color);
  text-align: center;
}

.footer .version {
  cursor: pointer;
}

@media (max-width: 800px) {
  .footer {
    gap: 10px;
    justify-content: space-evenly;
  }
}

@media (max-width: 550px) {
  .footer {
    gap: 5px;
  }
}