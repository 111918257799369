.container {
    /* display: flex;
    flex-wrap: wrap;
    gap: 10px 10px; */
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
}

@media (min-width:350px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (min-width:500px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

@media (min-width:700px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media (min-width:850px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
}

@media (min-width:1020px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    }
}