.uploadArea {
    position: relative;
    border: 2px dashed #ccc;
    padding: 16px;
    text-align: center;
    margin-bottom: 16px;
    cursor: pointer;
}

.dragOver {
    border-color: #2196f3;
    background-color: #e3f2fd;
}

.fileButton {
    display: none;
}

.dragAndDropText {
    margin-top: 12px !important;
    color: #777;
}

.uploadButton {
    visibility: visible;
}

.dragOver .uploadButton {
    visibility: hidden;
}