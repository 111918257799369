.analyseSkillsTextButton{
  display: inline-block;

}

.analyseSkillsTextButton .analyseSkillsTextButtonButton{
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}

.analyseSkillsTextButton #fileButton{
display: none;
}