.levelBox {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    /* width: 100%; */
    gap: 10px;
}

.levelsBox {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    align-self: stretch
}