/* custom-tree.css */

.entitiesPage {
    user-select: none;
}

.node__root rect,
.node__branch rect,
.node__leaf rect {
    /* stroke: none; */
    stroke-width: 1px;
    fill: var(--var-primary-color);
}

.node__root text,
.node__branch text,
.node__leaf text {
    stroke: none;
    fill: var(--var-on-primary-color);
    font-size: 12px;
    font-family: "Roboto",
        "Helvetica",
        "Arial",
        sans-serif;
}



.rd3t-tree-container .title {
    font-size: 14px;
    /* text-decoration: underline; */
    font-weight: 600;
}

.rd3t-tree-container .employee {
    fill: var(--var-secondary-color);
}

.rd3t-tree-container .employeeText {
    fill: #000;
}

/* .rd3t-tree-container .placeholder {
    fill: #fff;
    stroke: none;
} */