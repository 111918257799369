.userProfile {
    animation-name: animatetop;
    animation-duration: 0.4s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

/* .userProfile .title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 7px;
    margin-left: 5px;
} */

.userProfile .analyseTitle {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 5px;
}