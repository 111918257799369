.suggestion {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 3px 5px;
    cursor: pointer;
    width: 300px;
}


/* .suggestion:hover {
    background-color: var(--var-secondary-color);
    color: var(--var-on-secondary-color);
} */


.label {
    font-size: 16px;
}

.type {
    font-size: 13px;
}

.suggestionTexts {
    display: flex;
    flex-direction: column;
}

.icon {
    color: var(--var-secondary-color);
}

/* .MuiAutocomplete-clearIndicator {
    color: red !important;
} */