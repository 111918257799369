.stepPage {
  margin: 10px 10px;
}

.row {
  display: flex;
  gap: 10px;
  /* justify-content: center;
  align-items: center; */
}

.nextButton {
  margin-top: 30px;
  float: right;
}