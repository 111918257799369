.CertificateCompare {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    column-gap: 10px;
    /* border: solid 1px black; */
    /* border-radius: 5px; */

    /* align-items:stretch; */
    justify-content: center;

}

.CertificateCompare .certificate {
    display: flex;
    flex-grow: 1;
    border: solid 1px #efefef;
    border-radius: 10px;
    padding: 6px 7px;
    margin: 0px 0;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
    user-select: none;
    justify-content: space-between;

    flex-wrap: nowrap;
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1%));
    /* Grün mit variabler Helligkeit */
}

.CertificateCompare .certificate.missing {
    background-color: rgba(255, 138, 101, calc(var(--percent) * -1% - 0%));
    /* Rot mit variabler Helligkeit */
}

.CertificateCompare .certificate.negativ {
    background-color: rgba(255, 138, 101, calc(var(--percent) * -1% - 00%));
    /* Rot mit variabler Helligkeit */
}

.CertificateCompare.small {
    box-shadow: none;
    padding: 2px;
    gap: 5px 5px;
    border: none;
    border-radius: 0px;

}

.CertificateCompare.small .certificate {
    font-size: 14px;
    padding: 2px 3px;
    box-shadow: none;

}

.CertificateCompare .certificateItemTitle {
    margin-right: 10px;
    white-space: nowrap;
}

.CertificateCompare .certificateItemLevels {
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

.CertificateCompare .certificateItemLevel {

    float: right;
    border: solid 1px #eee;
    border-radius: 15px;
    padding: 0px 5px;
    background-color: #ffffff90;
    font-size: 16px;

    line-height: 18px;
}

.CertificateCompare .certificateItemLevel.wantedLevel {
    padding-left: 5px;
}

.CertificateCompare .certificateInner {
    width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

}