/* .editPermissionGroupsList {
    padding: 10px;

} */

.editPermissionGroupsListTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.editPermissionGroupsListTitle .edit {
    cursor: pointer;
}

.editPermissionGroupsList .permissionGroups {
    display: flex;
    gap: 17px;
    flex-direction: column;

    flex-wrap: wrap;
}

.editPermissionGroupsList .permissionGroup {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, .12);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 5px;
}

.editPermissionGroupsList .headLine {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.editPermissionGroupsList .permissionGroup .id,
.editPermissionGroupsList .permissionGroup .title {
    font-size: 16px;
    font-weight: 400;

}

/* .editPermissionGroupsList .permissionGroup.editMode .level, */
.editPermissionGroupsList .permissionGroup.editMode .title {
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    width: calc(100% - 20px);
}

.editPermissionGroupsList .permissionGroup .textsLine {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.editPermissionGroupsList .permissionGroup .title {
    font-size: 17px;
    font-weight: 600;
}

.editPermissionGroupsList .permissionGroup .id {
    font-size: 17px;
    font-weight: 600;
}

.editPermissionGroupsList .edit {
    cursor: pointer;
    padding: 5px;
}

.editPermissionGroupsList .btnRow {
    display: flex;
    gap: 15px;
}