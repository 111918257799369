.editSkillList {
    margin-bottom: 200px;
}

.editSkillList .skillInfo {
    display: grid;
    /* width: 100%; */
    grid-template-columns: 1fr 1fr auto;
    column-gap: 5px;
    align-items: center;
    border-bottom: solid 1px var(--var-table-border-color);
    /* padding: 1px 0px; */
}

.editSkillList .delete {
    max-width: 100px;
}

/* 
.editSkillList .skillTitle {
    font-size: 22px;
    border-bottom: solid 1px black;
    margin-bottom: 5px;
} */


.editSkillList .infoBoxSkill {
    width: 100%;
}

/* .editSkillList .infoBoxSkill td {
    border-bottom: solid 1px var(--var-table-border-color);
} */

.editSkillList .save {
    margin-top: 10px;
}

.editSkillList .info {
    display: flex;
    gap: 3px;
    align-items: center;
    word-break: break-word;
    /* border-bottom: solid 1px #00000030; */
    overflow-x: hidden;
    height: 100%;

    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2.5;
}