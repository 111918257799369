.companySettings {
    /* width: 100%; */
    display: grid;
}

.settingsTitle {
    width: 100%;
    display: flex;
    font-size: 22px;
    font-weight: 600;


    margin-left: 5px;

    gap: 10px;
}

.settingsTitle .editTitleIcon {
    cursor: pointer;

}

.companySettings .tabControl {
    margin-bottom: 10px;
}