.itemBadge {
    display: flex;
    border: solid 1px #a0a0a0;
    border-radius: 4px;
    padding: 0px 5px;
    background-color: #ffffff90;
    font-size: 0.8rem;
    line-height: 20px;
    cursor: initial;
    box-shadow: 0 2px 2px 0 #0003;
    align-items: center;
    gap: 1px;
}

.small {
    font-size: 13px;
    line-height: 18px;
}

.icon {
    display: flex;
}

.icon>* {
    font-size: 15px !important;
}