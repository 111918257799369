.salesContent {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    width: 100%;
}


.salesContent .salesDataSearchBox {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: center;
}

.orgUnitPageMonthBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    min-height: 40px;
    justify-content: space-evenly;
    border-left: #ccc solid 1px;
    /* justify-content: space-between; */
}

.orgUnitPageMonthBox.booking {
    background-color: rgba(119, 255, 119, 0.15);
}

.orgUnitPageMonthBox.mixed {
    background-color: rgba(242, 255, 0, 0.15);
}

.orgUnitPageMonthBox.noRevenue {
    background-color: rgb(255 130 237 / 15%);
}

.salesContent .nameColumn {
    width: 220px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.availabilitiesContent {
    --var-color-available: #fff;
    --var-color-externalProject: #70ac48;
    --var-color-internalProject: #febd00;
    --var-color-holiday: #5a9ad6;
}

.availabilitiesContent .bar {
    height: 30px;
    width: 100%;
    line-Height: 30px;
    cursor: auto;
    color: #000;
    font-Weight: bold;
    text-Shadow: 0 0 3px #fff;
    border: 1px solid #ccc;
}