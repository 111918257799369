.loadingDialog {
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding: 10px;
    animation-name: animatetop;
    animation-duration: 0.4s;
    background-color: #fff;
    overflow: hidden;
}

.loadingBox::backdrop {
    background: rgba(0, 0, 0, 0.5);
}