.tabContainer .tabHeader {
    display: flex;
    justify-content: space-evenly;
    /* gap:3px; */
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);
    user-select: none;
}

.tabContainer .tabHeader .tabTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    min-height: 45px;
    /* color: var(--var-on-secondary-color); */
    /* background-color: var(--var-secondary-color); */
    background-color: rgba(124, 126, 128, 0.4);
    color: rgba(124, 126, 128, 0.9);

    cursor: pointer;
    padding: 10px 10px;
    transition: background-color 0.5s ease;
    /* border-bottom: 3px solid var(--var-secondary-color); */
}

@media (max-width: 500px) {
    .tabContainer .tabHeader .tabTitle {
        padding: 5px 10px;
    }
}

.tabContainer .tabHeader .tabTitle:hover {
    /* color: var(--var-secondary-color); */
    background-color: rgba(124, 126, 128, 0.5);
}

.tabContainer .tabHeader .tabTitle.active {
    /* color: var(--var-primary-color); */
    color: var(--var-on-primary-color);
    font-weight: 600;
    border-top: 3px solid var(--var-primary-color);
    border-bottom: 3px solid var(--var-secondary-color);
    /* background-color: #fff; */
    background-color: var(--var-primary-color);

}

.tabContainer .tab {
    min-height: 100px;
    animation-name: animatetop;
    animation-duration: 0.4s;
}