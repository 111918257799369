.legendBox {
    display: flex;
    gap: 0px 15px;
    align-items: center;
    flex-wrap: wrap;
    float: right;
}

.legendItem {
    display: flex;
    gap: 5px;
    align-items: center;
}

.legendColor {
    width: 10px;
    height: 10px;
    border: solid 1px black;
}