.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin: 7px 0;
    padding: 6px 7px;
    border: solid 1px #efefef;
    border-radius: 5px;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
    user-select: none;
    flex-grow: 1;
    font-weight: 200;
    font-size: 0.8rem;
    transition: background-color 0.5s,
        box-shadow 0.5s,
        border 0.5s;
    background-color: rgb(255, 255, 255);
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
}

.main {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-items: stretch;
    width: 100%;
}

.negativ {
    background-color: rgba(255, 138, 101, calc(var(--percent) * -1% - 00%));
    /* Rot mit variabler Helligkeit */
}

.item .leftContent,
.item .rightContent {
    display: flex;
    gap: 5px;
    align-items: center;
}

.item:hover {
    background-color: rgba(180, 233, 115, calc(var(--percent) * 1% - 15%));
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .4);
    border: solid 1px #d3d3d3;
    transition: background-color 0.3s, box-shadow 0.3s, border 0.3s;
    font-weight: 600;
}

.item.negativ:hover {
    background-color: rgba(255, 138, 101, calc(var(--percent) * -1% - 00%));
}

.actions {
    display: flex;
    /* flex-wrap: wrap; */
}

.menuItem {
    display: 'flex';
    gap: '0.5rem';
    align-items: 'center';
}

.iconButton {
    padding: 0 !important;
}