.row {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0px;
    align-items: center;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-items: stretch;
    flex-grow: 1;
}

.alignTop {
    align-items: flex-start;
}